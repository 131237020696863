/* eslint-disable */
import moment from "moment";

export const serialize = function (obj) {
	var str = [];
	for (var p in obj)
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	return `?${str.join("&")}`;
};

export const isScheduleAvailable = function (day) {
	const now = moment();
	const schedule = moment(day);
	const diffInMinutes = moment(schedule).diff(now, "minutes");
	return !(Math.abs(diffInMinutes) <= 30);
};
