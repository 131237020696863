import axios from "axios";
import { environments } from "../config/configManager";

const client = axios.create({
	baseURL: environments.tmed,
});

client.interceptors.request.use(
	(config) => {
		// Do something before request is sent
		const accessToken = localStorage.getItem("token");
		// eslint-disable-next-line no-param-reassign
		if (accessToken) config.headers.Authorization = `${accessToken}`;
		return config;
	},
	(error) => Promise.reject(error)
);

client.interceptors.response.use(
	(response) => response,
	(error) => {
		switch (error.response?.status) {
			default:
				break;
		}
		return Promise.reject(error);
	}
);

export default client;
