import Http from '../http/tmed';
import HttpPartner from '../http/partner';
import axios from 'axios';
import { environments } from '../config/configManager';

// eslint-disable-next-line
import { serialize } from '@/util';

export const calendar = (params) => Http.get(`/calendar${serialize(params)}`);
export const fetchDoctors = (occupation) => Http.get(`/doctors/${occupation}?limit=1000`);
export const fetchOccupations = () => Http.get(`/tmed/config`);
export const schedule = (params) => Http.post(`/calendar`, params);
export const reschedule = (params) => Http.put(`/calendar`, params);
export const cancel = (params) => Http.delete(`/calendar/${params.cpf}/${params.scheduleId}`);
export const fetchSchedulesHistory = (params) => Http.get(`/schedule/all/${params.cpf}${serialize(params.query)}`)
// QUEUE
export const enterQueue = (params) => Http.post(`/queue/wait`, params);
export const cancelQueue = (params) => Http.delete(`/queue/${params}`);
export const checkQueue = (params) => Http.get(`/queue${serialize(params)}`);
export const avaliation = (payload) =>
  HttpPartner.post(`meetings/avaliation/meets-code/${payload.meets}`, {
    ...payload,
  });
export const sendFile = (payload) => Http.post(`/create/patient/docs`, payload);

// OVERBOOK QUEUE
export const enterOverbookQueue = (params) => axios.post(`${environments.tmedQueue}/doctor/queue/enter`, params);
export const quitOverbookQueue = (params) =>
  axios.delete(`${environments.tmedQueue}/doctor/queue/leave/${params.cpf}/${params.doctorId}`);

export const wsUrl = environments.socket;
