import Http from "../http/tmed";
// eslint-disable-next-line

export const createUser = (params) => Http.post(`/user`, params);
export const getUser = (params) => Http.get(`/auth/user/${params}`);
export const authenticateUser = (code) => Http.get(`/autorize/${code}`);
export const getSchedule = (params) => Http.get(`/schedule/${params}`);
export const getUserByPhone = (params) =>
	Http.get(`/user/phone/${params.cpf}/${params.phone}`);
export const registerAuditLog = (params) => Http.post(`/audit/save`, params);
