import config from './index.json'

const environmentMapping = {
  'dev-telemedicina.medex.net.br': 'development',
  'uat-telemedicina.medex.net.br': 'uat',
  'partner-telemedicina.medex.net.br': 'ppr',
  'partner-klinitelemedicina.medex.net.br': 'ppr-klini',
}

const currentHost = location.host
const currentEnvironment = environmentMapping[currentHost] || process.env.NODE_ENV || 'production'
const productionHttp = `https://${currentHost}/v1`

export const environments = {
  tmed: currentEnvironment === 'production' ? productionHttp : config[currentEnvironment]?.tmed,
  tmedQueue: config[currentEnvironment]?.tmed || config.production.tmed,
  partner: config[currentEnvironment]?.partner || config.production.partner,
  socket: config[currentEnvironment]?.socket || config.production.socket,
}
